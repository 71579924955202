import { combineReducers } from "redux"

import { drawerReducer } from "./drawer"
import { favoritesReducer } from "./favorites"
import { gdprReducer } from "./gdpr"
import { searchReducer } from "./search"

export const rootReducer = combineReducers({
  drawer: drawerReducer,
  favorites: favoritesReducer,
  gdpr: gdprReducer,
  search: searchReducer,
})

export type RootState = ReturnType<typeof rootReducer>
