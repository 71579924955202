const CLOSE = "buybags/drawer/CLOSE"
const OPEN = "buybags/drawer/OPEN"

type Drawer = "FavoritesDrawer" | "MenuDrawer"

export interface DrawerState {
  openDrawer: Drawer | null
}

interface CloseAction {
  type: typeof CLOSE
}

interface OpenAction {
  type: typeof OPEN
  payload: { drawer: Drawer }
}

export type DrawerAction = CloseAction | OpenAction

const initialState: DrawerState = {
  openDrawer: null,
}

export const drawerReducer = (state = initialState, action: DrawerAction): DrawerState => {
  switch (action.type) {
    case CLOSE:
      return {
        ...state,
        openDrawer: null,
      }
    case OPEN:
      return {
        ...state,
        openDrawer: action.payload.drawer,
      }
    default:
      return state
  }
}

export function openFavoritesDrawer(): OpenAction {
  return { type: OPEN, payload: { drawer: "FavoritesDrawer" } }
}

export function openMenuDrawer(): OpenAction {
  return { type: OPEN, payload: { drawer: "MenuDrawer" } }
}

export function closeDrawer(): CloseAction {
  return { type: CLOSE }
}
