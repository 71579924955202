import { createMuiTheme } from "@material-ui/core"
import { red } from "@material-ui/core/colors"
import { deDE } from "@material-ui/core/locale"

const theme = createMuiTheme(
  {
    palette: {
      background: {
        default: "#fff",
      },
      error: {
        main: red.A700,
      },
      primary: {
        main: "#000",
      },
      secondary: {
        main: "#fff",
      },
    },
    props: {
      MuiTypography: {
        gutterBottom: true,
        variantMapping: {
          body1: "p",
          body2: "p",
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "div",
          subtitle2: "div",
        },
      },
    },
    typography: {
      fontFamily: [`Georgia`, `Times`, `"Times New Roman"`, `serif`].join(","),
      h1: {
        fontSize: "2.1rem",
        fontStyle: "italic",
        fontWeight: 400,
        letterSpacing: "0em",
        lineHeight: 1.04,
      },
      h2: {
        fontSize: "1.5rem",
        fontStyle: "italic",
        fontWeight: 400,
        letterSpacing: "0.00735em",
        lineHeight: 1.17,
      },
      h3: {
        fontSize: "1.1rem",
        fontStyle: "italic",
        fontWeight: 400,
        letterSpacing: "0em",
        lineHeight: 1.33,
      },
      h4: {
        fontSize: "0.9rem",
        fontStyle: "italic",
        fontWeight: 500,
        letterSpacing: "0.0075em",
        lineHeight: 1.6,
      },
      subtitle1: {
        textTransform: "uppercase",
      },
    },
  },
  deDE
)

export default theme
