const UPDATE = "buybags/gdpr/UPDATE"

export interface GdprState {
  consent: boolean
}

interface UpdateAction {
  type: typeof UPDATE
  payload: { consent: boolean }
}

export type GdprAction = UpdateAction

const initialState: GdprState =
  typeof localStorage !== "undefined"
    ? JSON.parse(localStorage.getItem("gdpr") || `{ "consent": false }`)
    : { consent: false }

export const gdprReducer = (state = initialState, action: GdprAction): GdprState => {
  switch (action.type) {
    case UPDATE: {
      const { consent } = action.payload
      const newState = { ...state, consent }
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("gdpr", JSON.stringify(newState))
      }
      return newState
    }
    default:
      return state
  }
}

export function giveConsent(): UpdateAction {
  return { type: UPDATE, payload: { consent: true } }
}
