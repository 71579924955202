module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"noHash":true,"noQueryString":true,"noTrailingSlash":false,"siteUrl":"https://www.buybags.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"buybags","short_name":"buybags","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"static/buybags-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f6467eaad0badd8588b673535159ce40"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Startseite","useClassNames":true,"trailingSlashes":true,"exclude":["/dev-404-page","/404","/404.html"],"crumbLabelUpdates":[{"pathname":"/agb","crumbLabel":"AGB"},{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/blog/fruehlingstrends-2020","crumbLabel":"Frühlingstrends 2020"},{"pathname":"/blog/wintertrends-2019-2020","crumbLabel":"Wintertrends 2019/2020"},{"pathname":"/datenschutz","crumbLabel":"Datenschutz"},{"pathname":"/impressum","crumbLabel":"Impressum"},{"pathname":"/marken","crumbLabel":"Marken"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"id":"GTM-TD6QTC8"},
    },{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-vitals/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-154211866-1","metrics":["FID","TTFB","LCP","CLS","FCP"],"eventCategory":"Web Vitals","includeInDevelopment":false,"debug":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-guess-js/gatsby-browser.js'),
      options: {"plugins":[],"GAViewID":"207695688","minimumThreshold":0.03,"period":{"startDate":"2020-06-30T22:00:00.000Z","endDate":"2021-12-17T08:51:30.158Z"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"projectRoot":"/Users/asd/Projects/buybags/frontend","configDir":"./src"},
    }]
