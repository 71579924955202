import { navigate } from "gatsby"

import { SearchResultData } from "../../interfaces/searchResult"

const SET_LOADING = "buybags/search/SET_LOADING"
const SET_QUERY = "buybags/search/SET_QUERY"
const SET_RESULTS = "buybags/search/SET_RESULTS"

export interface SearchState {
  loading: boolean
  query: string
  results: SearchResultData[]
}

interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: { loading: boolean }
}

interface SetQueryAction {
  type: typeof SET_QUERY
  payload: { query: string }
}

interface SetResultsAction {
  type: typeof SET_RESULTS
  payload: { results: SearchResultData[] }
}

export type SearchAction = SetLoadingAction | SetQueryAction | SetResultsAction

const initialState: SearchState = {
  loading: false,
  query: (typeof location !== "undefined" && new URLSearchParams(location.search).get(`q`)) || ``,
  results: [],
}

export const searchReducer = (state = initialState, action: SearchAction): SearchState => {
  switch (action.type) {
    case SET_LOADING: {
      const { loading } = (action as SetLoadingAction).payload
      return { ...state, loading }
    }
    case SET_QUERY: {
      const { query } = (action as SetQueryAction).payload
      if (typeof location !== "undefined") {
        let to = location.pathname
        if (query.length > 0) {
          to = `${to}?q=${encodeURIComponent(query)}`
        }
        navigate(to, { replace: true })
      }
      return { ...state, query }
    }
    case SET_RESULTS: {
      const { results } = (action as SetResultsAction).payload
      return { ...state, results }
    }
    default: {
      return state
    }
  }
}

export function setLoading(loading: boolean): SetLoadingAction {
  return { type: SET_LOADING, payload: { loading } }
}

export function setQuery(query: string): SetQueryAction {
  return { type: SET_QUERY, payload: { query } }
}

export function setResults(results: SearchResultData[]): SetResultsAction {
  return { type: SET_RESULTS, payload: { results } }
}
