import { applyMiddleware, createStore as reduxCreateStore, Store } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"

import { rootReducer } from "./ducks"

export const createStore = (): Store => {
  const preloadedState = {}
  const enhancer = composeWithDevTools(applyMiddleware())
  return reduxCreateStore(rootReducer, preloadedState, enhancer)
}
