import { Favorite } from "../../interfaces/favorite"

const ADD = "buybags/favorites/ADD"
const REMOVE = "buybags/favorites/REMOVE"

export interface FavoritesState {
  favorites: Favorite[]
}

interface AddAction {
  type: typeof ADD
  payload: { favorite: Favorite }
}

interface RemoveAction {
  type: typeof REMOVE
  payload: { favorite: Favorite }
}

export type FavoritesAction = AddAction | RemoveAction

const initialState: FavoritesState = {
  favorites:
    typeof localStorage !== "undefined"
      ? JSON.parse(localStorage.getItem("favorites") || `[]`)
      : [],
}

export const favoritesReducer = (state = initialState, action: FavoritesAction): FavoritesState => {
  let newFavorites
  switch (action.type) {
    case ADD:
      newFavorites = state.favorites.concat([action.payload.favorite])
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("favorites", JSON.stringify(newFavorites))
      }
      return {
        ...state,
        favorites: newFavorites,
      }
    case REMOVE:
      newFavorites = state.favorites.filter(
        (favorite) => favorite.slug !== action.payload.favorite.slug
      )
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("favorites", JSON.stringify(newFavorites))
      }
      return {
        ...state,
        favorites: newFavorites,
      }
    default:
      return state
  }
}

export function addFavorite(favorite: Favorite): AddAction {
  return { type: ADD, payload: { favorite } }
}

export function removeFavorite(favorite: Favorite): RemoveAction {
  return { type: REMOVE, payload: { favorite } }
}
